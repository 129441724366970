@font-face {
  font-family: header;
  src: url(../Audiowide-Regular.ttf);
}
@font-face {
  font-family: textP;
  src: url(../25381880192.ttf.otf);
}
.snkwars {
  font-size: 3rem;
  font-weight: bolder;
}
hr {
  margin-top: 3rem;
}
h1 {
  font-family: header !important;
  color: rgb(201, 201, 201);
}
h2 {
  font-family: header !important;
  color: rgb(201, 201, 201);
}
p {
  font-family: textP !important;
  color: rgb(201, 201, 201);
  font-weight: lighter !important;
  font-size: 1.2rem !important;
}
.MuiTypography-root {
  font-family: textP !important;
  font-weight: 400 !important;
  font-size: 1.1rem !important;
}
.glow {
  color: #fff;
  animation: glow 1s ease-in-out infinite alternate;
}

@keyframes glow {
  from {
    text-shadow: 0 0 5px #fff, 0 0 10px #fff, 0 0 10px #75e6e4;
  }

  to {
    text-shadow: 0 0 5px #fff, 0 0 5px #75e6e4, 0 0 5px #75e6e4;
  }
}
.section2__logo {
  width: 58%;
  height: 58%;
  position: relative;
  right: 4.5px !important;
}
@media screen and (max-width: 600px) {
  .section2__logo {
    width: 98%;
    height: 98%;
    position: relative;
    right: 5px !important;
  }
}
